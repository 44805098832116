<!--
 * @Description: 短信下发
 * @Author: ChenXueLin
 * @Date: 2021-10-20 13:55:12
 * @LastEditTime: 2022-07-15 15:51:24
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="customerName">
              <el-input
                v-model="searchForm.customerName"
                placeholder="客户名称"
                title="客户名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="receiveName">
              <el-input
                v-model="searchForm.receiveName"
                placeholder="接收人名称"
                title="接收人名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="receiveNumber">
              <el-input
                v-model="searchForm.receiveNumber"
                placeholder="接收号码"
                title="接收号码"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="smsContent">
              <el-input
                v-model="searchForm.smsContent"
                placeholder="短信内容"
                title="短信内容"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="smsTypeCode">
              <e6-vr-select
                v-model="searchForm.smsTypeCode"
                :data="smsTypeList"
                placeholder="短信类型"
                title="短信类型"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="查询时间"
                ref="effectiveDatePicker"
                v-model="searchForm.createTime"
                title="查询时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="查询时间（始）"
                end-placeholder="查询时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-add_line" title="新增" @click="handleAdd"></i>
          <i class="e6-icon-export_line" title="导出" @click="handleExport"></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          ref="tableList"
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
        >
          <el-table-column type="selection" width="40"></el-table-column>
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <!-- 新建弹框 -->
    <el-dialog
      v-dialogDrag
      title="添加短信"
      :visible="addVisible"
      width="550px"
      :close-on-click-modal="false"
      v-loading="dialogLoading"
      :element-loading-background="loadingBackground"
      :before-close="handleClose"
      custom-class="add-dialog"
    >
      <el-form
        label-width="110px"
        ref="addForm"
        :rules="addFormRules"
        :model="addForm"
      >
        <el-form-item label="发出的号码">
          {{ 204 }}
        </el-form-item>
        <el-form-item label="接受短信号码" prop="receiveNumber">
          <el-input
            placeholder="接受短信号码"
            v-model="addForm.receiveNumber"
            type="textarea"
            :rows="5"
            @input="
              addForm.receiveNumber = isNaN(addForm.receiveNumber)
                ? addForm.receiveNumber.replace(/[^\d\,]/g, '')
                : addForm.receiveNumber
            "
          ></el-input>
          <!--   @input="handleInput" -->
        </el-form-item>
        <el-form-item label="发送的短信内容" prop="smsContent">
          <el-input
            placeholder="发送的短信内容"
            v-model="addForm.smsContent"
            type="textarea"
            :rows="5"
            maxlength="255"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="服务备注" prop="remark">
          <el-input
            placeholder="服务备注"
            v-model="addForm.remark"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            maxlength="100"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { getSmsList, createSms, findDownList, exportSmsList } from "@/api";
import { exportData } from "@/utils/download";
import { printError } from "@/utils/util";
export default {
  name: "sendMessage",
  components: {},
  mixins: [listPage, listPageReszie, base],
  data() {
    return {
      total: 0,
      dialogLoading: false,
      searchForm: {
        customerName: "", //客户名称
        receiveName: "", //接收人名称
        receiveNumber: "", //接收号码
        smsContent: "", //短信内容
        smsTypeCode: "", //短信类型码值
        createTime: [],
        beginTimeTimestamp: "",
        endTimeTimestamp: "",
        pageIndex: 1,
        pageSize: 20
      },
      smsTypeList: [],
      columnData: [
        {
          fieldName: "customerName",
          display: true,
          fieldLabel: "客户名称",
          width: 160,
          disabled: false,
          fixed: true,
          align: "center"
        },
        {
          fieldName: "receiveName",
          display: true,
          fieldLabel: "接收人名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "receiveNumber",
          display: true,
          fieldLabel: "接收号码",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "smsType",
          display: true,
          fieldLabel: "短信类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "smsContent",
          display: true,
          fieldLabel: "短信内容",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "sendTimeString",
          display: true,
          fieldLabel: "发送时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userName",
          display: true,
          fieldLabel: "服务人名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "remark",
          display: true,
          fieldLabel: "备注",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [],
      //*****添加短信下发 */
      addVisible: false,
      smsTypeCode: 1,
      addForm: {
        receiveNumber: "", //接收短信号码
        smsContent: "", //发送短信内容
        remark: "" //备注
      },
      addFormRules: {
        receiveNumber: [
          {
            required: true,
            message: "请输入接收短信号码",
            trigger: ["blur", "change"]
          }
        ],
        smsContent: [
          {
            required: true,
            message: "请输入发送短信内容",
            trigger: ["blur", "change"]
          }
        ]
      },
      refTable: "tableList",
      queryListAPI: getSmsList
    };
  },
  computed: {},
  watch: {
    // 创建开始时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.beginTimeTimestamp = val[0] || "";
        this.searchForm.endTimeTimestamp = val[1] || "";
      }
    }
  },
  created() {
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM();
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    async initData() {
      try {
        let promiseList = [findDownList(["smsType"])];
        let [smsTypeRes] = await Promise.all(promiseList);
        //短信类型
        this.smsTypeList = this.getFreezeResponse(smsTypeRes, {
          path: "data.smsType"
        });
      } catch (error) {
        printError(error);
      }
    },
    handleInput(val) {
      console.log(/^[0-9,]+$/.test(val));
      // addForm.receiveNumber = isNaN(addForm.receiveNumber)
      //   ? addForm.receiveNumber.replace(/^(\d+[,])*(\d+)$/, "")
      //   : addForm.receiveNumber;
    },
    /****新增******/
    //点击新增按钮
    handleAdd() {
      this.addVisible = true;
    },
    //确定新增服务类型
    handleConfirm() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          this.addReq();
        }
      });
    },
    //添加短信请求
    async addReq() {
      try {
        this.dialogLoading = true;
        let res = await createSms({ ...this.addForm, smsTypeCode: 1 });
        if (res.code == "OK") {
          this.$message.success("新增成功");
          this.handleClose();
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //点击取消
    handleClose() {
      this.addForm = {
        receiveNumber: "", //接收短信号码
        smsContent: "", //发送短信内容
        remark: "" //备注
      };
      this.$refs.addForm.resetFields();
      this.addVisible = false;
    },
    // 导出
    handleExport() {
      exportData(this, exportSmsList);
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .add-dialog {
  .el-dialog__body {
    padding: 20px;
    .el-form {
      .el-input {
        width: 340px;
      }
      .el-textarea {
        width: 340px;
      }
    }
  }
}
</style>
